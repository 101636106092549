import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { FormattedMessage } from 'gatsby-plugin-intl';

const OurClients = () => {
  const { allClients } = useStaticQuery(
    graphql`
      query {
        allClients {
          nodes {
            imageUrl {
              publicURL
            }
            name
          }
        }
      }
    `
  );

  return (
    <div className="meet-our-clients" id="meet-our-clients">
      <div className="row main-well main-well-centered">
        <h1>
          <FormattedMessage id="clients.title" />
        </h1>
        <hr className="divider" />
        <ul className="clients-list">
          {allClients.nodes.map(client => (
            <li key={client.name}>
              <img src={client.imageUrl.publicURL} alt={client.name} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default OurClients;
