import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { FormattedMessage, Link } from 'gatsby-plugin-intl';

const WorkWithUs = () => {
  const {
    allMarkdownRemark: { jobs },
  } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(filter: { frontmatter: { availableSpots: { gt: 0 } } }) {
          jobs: nodes {
            info: frontmatter {
              title
              slug
              stack
              category
              availableSpots
            }
          }
        }
      }
    `
  );
  return (
    <div className="work-with-us" id="work-with-us">
      <div className="row main-well main-well-centered">
        <h1>
          <FormattedMessage id="work_with_us.title" />
        </h1>
        <p>
          <FormattedMessage id="work_with_us.desc" />
        </p>
        <hr className="divider" />

        <div className="spots">
          {jobs.map(({ info }) => (
            <div key={info.slug} className="spot">
              <div className="spot-wrapper">
                <div className="spots-number Back-end hide-for-small">
                  <span className="icon" />
                  <span className="ribbon">
                    <FormattedMessage id="work_with_us.available_spots" values={info} />
                  </span>
                </div>
                <h2>{info.title}</h2>
                <p>{info.stack}</p>
                <Link to={info.slug} className="button">
                  <FormattedMessage id="work_with_us.cta_details" />
                </Link>
              </div>
            </div>
          ))}
        </div>
        <a href="mailto:rh@nextt.com.br" className="button">
          <FormattedMessage id="work_with_us.cta_apply" />
        </a>
      </div>
    </div>
  );
};

export default WorkWithUs;
