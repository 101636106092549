import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import './index.scss';
import { FormattedMessage, Link } from 'gatsby-plugin-intl';
import Layout from '../components/layout';
import SEO from '../components/seo';
import OurClients from '../components/index/our-clients';
import Contact from '../components/index/contact';
import WorkWithUs from '../components/index/work-with-us';
import HowWeDo from '../components/index/how-we-do';

const IndexPage = () => {
  return (
    <Layout>
      <SEO />
      <div id="intro" className="intro">
        <div className="wrapper">
          <h1>
            <FormattedMessage id="intro.title" />
          </h1>
          <h2>
            <FormattedMessage id="intro.subtitle" />
          </h2>
          <Link to="/#how-we-do" className="button">
            <FormattedMessage id="intro.cta_what" /> <FontAwesomeIcon icon={faAngleDown} />
          </Link>
        </div>
      </div>

      <HowWeDo />
      <OurClients />
      <WorkWithUs />
      <Contact />
    </Layout>
  );
};

export default IndexPage;
