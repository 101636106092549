import React from 'react';
import { FormattedMessage, Link } from 'gatsby-plugin-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import UnderstandingBaloonsImage from '../../images/inline/understanding-baloons.svg';
import UnderstandingManImage from '../../images/inline/understanding-man.svg';
import UnderstandingWomanImage from '../../images/inline/understanding-woman.svg';
import DesigningImage from '../../images/inline/designing.svg';
import DevelopingImage from '../../images/inline/developing.svg';

const HowWeDo = () => (
  <div id="how-we-do" className="row main-well main-well-centered how-we-do">
    <h1>
      <FormattedMessage id="what.title" />
    </h1>
    <p className="featured">
      <FormattedMessage id="what.desc" />
    </p>
    <hr className="divider" />
    <div className="medium-4 small-12 columns understanding">
      <div className="illustration">
        <UnderstandingBaloonsImage />
        <UnderstandingManImage />
        <UnderstandingWomanImage />
      </div>
      <h2>
        <FormattedMessage id="what.understanding.title" />
      </h2>
      <hr className="divider" />
      <ul>
        <li>
          <FormattedMessage id="what.understanding.user_interviews" />
        </li>
        <li>
          <FormattedMessage id="what.understanding.quantitative_research" />
        </li>
        <li>
          <FormattedMessage id="what.understanding.usability_testing" />
        </li>
        <li>
          <FormattedMessage id="what.understanding.personas_scenarios" />
        </li>
        <li>
          <FormattedMessage id="what.understanding.metrics_analysis" />
        </li>
        <li>
          <FormattedMessage id="what.understanding.IA" />
        </li>
      </ul>
    </div>
    <div className="medium-4 small-12 columns designing">
      <div className="illustration">
        <DesigningImage />
      </div>
      <h2>
        <FormattedMessage id="what.designing.title" />
      </h2>
      <hr className="divider" />
      <ul>
        <li>
          <FormattedMessage id="what.designing.prototyping" />
        </li>
        <li>
          <FormattedMessage id="what.designing.digital_ui" />
        </li>
        <li>
          <FormattedMessage id="what.designing.mobile" />
        </li>
        <li>
          <FormattedMessage id="what.designing.responsive" />
        </li>
        <li>
          <FormattedMessage id="what.designing.styleguide" />
        </li>
        <li>
          <FormattedMessage id="what.designing.icons" />
        </li>
      </ul>
    </div>
    <div className="medium-4 small-12 columns developing">
      <div className="illustration">
        <DevelopingImage />
      </div>
      <h2>
        <FormattedMessage id="what.developing.title" />
      </h2>
      <hr className="divider" />
      <ul>
        <li>
          <FormattedMessage id="what.developing.responsive_front_end" />
        </li>
        <li>
          <FormattedMessage id="what.developing.front_back_integration" />
        </li>
        <li>
          <FormattedMessage id="what.developing.js_apis" />
        </li>
      </ul>
    </div>
    <hr className="clear" />
    <Link to="/#contact-us" className="button">
      <FormattedMessage id="what.cta_contact" /> <FontAwesomeIcon icon={faAngleDown} />
    </Link>
  </div>
);

export default HowWeDo;
