import { faFacebook, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { useStaticQuery, graphql } from 'gatsby';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import { of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';

const Contact = () => {
  const {
    site: {
      siteMetadata: {
        contact: { facebook, twitter, linkedin, address, phone, email },
        apiKey,
      },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          contact {
            facebook {
              url
            }
            twitter {
              url
            }
            linkedin {
              url
            }
            address {
              street
              neighborhood
              city
              state
              country
              zip
            }
            phone
            email
          }
          apiKey
        }
      }
    }
  `);

  const intl = useIntl();
  const { register, handleSubmit, errors } = useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitFeeback, setSubmitFeedback] = useState(null);
  const neighborhoodCityStateCountry = `${address.neighborhood}, ${address.city}, ${address.state} - ${address.country}`;

  const onSubmit = data => {
    of(data)
      .pipe(
        tap(() => setSubmitLoading(true)),
        // from form inputs
        map(body => {
          // transform to ajax request parameter for new lead (https://developers.getbase.com/docs/rest/reference/leads)
          return {
            url: '/api/crm/leads',
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              'x-api-key': apiKey,
            },
            body,
          };
        }),
        switchMap(request => ajax(request)) // make new lead request
      )
      .subscribe(
        lead => {
          setSubmitFeedback({
            type: 'success',
            messageId: 'contact.form.success',
          });
          setSubmitLoading(false);
        },
        error => {
          setSubmitFeedback({
            type: 'alert',
            messageId: 'contact.form.error',
            email,
          });
          setSubmitLoading(false);
        }
      );
  };

  return (
    <div className="row main-well main-well-centered contact-us" id="contact-us">
      <h1>
        <FormattedMessage id="contact.title" />
      </h1>
      <p>
        <FormattedMessage id="contact.desc" />
      </p>
      <hr className="divider" />
      <div className="columns medium-6 small-12 text-left">
        <form id="contact-form" onSubmit={handleSubmit(onSubmit)}>
          <div className={errors.name ? `error` : ``}>
            {errors.name && (
              <span className="error-message" data-test-id="name-error">
                <FormattedMessage id={errors.name.message} />
              </span>
            )}
            <label htmlFor="name">
              <FormattedMessage id="contact.form.name" />
            </label>
            <input
              type="text"
              placeholder={intl.formatMessage({ id: 'contact.form.name' })}
              id="name"
              name="name"
              data-test-id="name"
              ref={register({ required: 'contact.form.required' })}
            />
          </div>

          <div className={errors.email ? `error` : ``}>
            {/* <span className="error-message"><FormattedMessage id="contact.form.email.invalid" /></span> */}
            {errors.email && (
              <span className="error-message" data-test-id="email-error">
                <FormattedMessage id={errors.email.message} />
              </span>
            )}
            <label htmlFor="email">
              <FormattedMessage id="contact.form.email" />
            </label>
            <input
              type="email"
              placeholder={intl.formatMessage({ id: 'contact.form.email' })}
              id="email"
              name="email"
              data-test-id="email"
              ref={register({
                required: 'contact.form.required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'contact.form.invalid_email',
                },
              })}
            />
          </div>

          <div>
            <label htmlFor="company">
              <FormattedMessage id="contact.form.company" />
            </label>
            <input
              type="text"
              placeholder={intl.formatMessage({ id: 'contact.form.company' })}
              id="company"
              name="company"
              data-test-id="company"
              ref={register}
            />
          </div>

          <div>
            <label htmlFor="phone">
              <FormattedMessage id="contact.form.phone" />
            </label>
            <input
              type="text"
              placeholder={intl.formatMessage({ id: 'contact.form.phone' })}
              id="phone"
              name="phone"
              data-test-id="phone"
              ref={register}
            />
          </div>

          <div>
            <label htmlFor="mobile">
              <FormattedMessage id="contact.form.mobile" />
            </label>
            <input
              type="text"
              placeholder={intl.formatMessage({ id: 'contact.form.mobile' })}
              id="mobile"
              name="mobile"
              data-test-id="mobile"
              ref={register}
            />
          </div>

          <div className={errors.message ? `error textarea` : `textarea`}>
            {errors.message && (
              <span className="error-message" data-test-id="message-error">
                <FormattedMessage id={errors.message.message} />
              </span>
            )}
            <label htmlFor="message">
              <FormattedMessage id="contact.form.message" />
            </label>
            <textarea
              placeholder={intl.formatMessage({ id: 'contact.form.message' })}
              id="message"
              name="message"
              data-test-id="message"
              ref={register({ required: 'contact.form.required' })}
            />
          </div>
          {submitFeeback && (
            <div className={`alert-box radius ${submitFeeback.type}`} data-test-id="submit-feedback">
              <FormattedMessage id={submitFeeback.messageId} values={submitFeeback} />
            </div>
          )}
          <button type="submit" className="button" data-test-id="contact-submit" disabled={submitLoading}>
            {!submitLoading && <FormattedMessage id="contact.form.submit" />}
            {submitLoading && <FontAwesomeIcon icon={faCircleNotch} spin />}
          </button>
        </form>
      </div>

      <div className="columns medium-5 small-12 text-left">
        <h2>
          <FormattedMessage id="contact.social.title" />
        </h2>
        <div className="social">
          <a href={facebook.url}>
            <FontAwesomeIcon icon={faFacebook} size="3x" />
          </a>
          <a href={twitter.url}>
            <FontAwesomeIcon icon={faTwitter} size="3x" />
          </a>
          <a href={linkedin.url}>
            <FontAwesomeIcon icon={faLinkedin} size="3x" />
          </a>
        </div>
        <h2>
          <FormattedMessage id="contact.address.title" />
        </h2>
        <p className="address">
          {address.street}
          <br />
          {neighborhoodCityStateCountry} <br />
          <a href={`tel:${phone}`}>{phone}</a>
        </p>
        <div className="map">
          <iframe
            title="Nextt at Google Maps"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d883.9875412537242!2d-48.55092167079775!3d-27.595074610382028!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9527382365f29001%3A0xdd781eb049a01666!2sR.+Mal.+Guilherme%2C+147+-+Centro%2C+Florian%C3%B3polis+-+SC!5e0!3m2!1sen!2sbr!4v1476727761160"
            width="100%"
            height="100%"
            frameBorder="0"
          />
        </div>
        <a
          href="https://www.google.com.br/maps/place/R.+Mal.+Guilherme,+147+-+Centro,+Florian%C3%B3polis+-+SC/@-27.5950746,-48.5509217,19z/data=!3m1!4b1!4m5!3m4!1s0x9527382365f29001:0xdd781eb049a01666!8m2!3d-27.5950758!4d-48.5503745?hl=en"
          className="button"
        >
          <FormattedMessage id="contact.address.cta_directions" />
        </a>
      </div>
    </div>
  );
};

export default Contact;
